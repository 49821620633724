<!--
 * @Author: lbh
 * @Date: 2022-12-14 15:59:48
 * @LastEditors: lbh
 * @LastEditTime: 2023-07-12 16:29:04
 * @Description: file content
-->
<template>
  <div>
    <div class="px-items">
      <div
        class="item"
        v-for="(item,index) in dataList.length * 2"
        :key="index"
      >
        <template v-if="index %2 == 0">
          <div class="title-box">
            <div
              v-if="dataList[index]"
              class="title"
              v-html="$util.rex.getHtml(dataList[index].title)"
            ></div>
            <div
              v-if="dataList[item]"
              class="title"
              v-html="$util.rex.getHtml(dataList[item].title)"
            ></div>
          </div>
          <div class="
              content-box">
            <div
              v-if="dataList[index]"
              class="desc"
              v-html="$util.rex.getHtml(dataList[index].desc)"
            ></div>
            <div
              v-if="dataList[item]"
              class="desc"
              v-html="$util.rex.getHtml(dataList[item].desc)"
            ></div>
          </div>
        </template>
      </div>
    </div>

    <div
      class="aa"
      v-if="1==2"
    >
      <div class="bb">
        <div class="bb-r"></div>
        <div class="bb-i">
          <div
            class="bb-ic"
            v-for="(item,index) in lengItem"
            :key="item"
            :style="`transform: rotateZ(${getR(index)}deg);`"
          >
            <div :style="`transform: rotateZ(-${getR(index)}deg);`">{{item}}</div>
            <div>222</div>
          </div>
        </div>
      </div>
      <div class="cc">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      lengItem: 3,
      dataList: [
        { title: "Q：點解餐廳要用雲端POS餐飲系統？", desc: "A：雲端POS餐飲系統幫助老闆可以實時管理餐廳，所有數據通過雲端即時傳送，就算人唔喺餐廳都可以及時處理問題！從落單到結賬都可以喺一個智能裝置完成，係每間餐廳從經營到營利嘅必備工具" },
        { title: "Q：餐廳用QR CODE手機落單有咩好？", desc: "A：餐飲業最常面對嘅問題就係：租金高、人工高、食材成本高及利潤低。為咗可以開源節流，使用QR Code手機落單可以節省人手成本，提高翻檯率，有助餐廳增加收入" },
        { title: "Q：樓面同事成日落錯單，收銀同事又容易埋錯腳，有沒辦法可以解決啊？", desc: "A：餐廳用雲端POS餐飲系統結合QR Code手機落單，客人落單直接送廚房出飛，仲可以通過手機落單俾埋錢，全程減少人手處理步驟及出錯機會，大大減輕餐廳營運壓力" },
        { title: "Q：我間餐廳唔係咩連鎖餐廳，有無需要用會員系統呢？", desc: "A：會員系統有助於餐廳提高熟客粘性及吸引新客消費，通過會員系統，可以增加餐廳及客人之間嘅了解及信任，令到客人更願意到餐廳消費，更可以令客人成為餐廳無形中嘅“宣傳人員”，為餐廳傳播口碑" },
        { title: "Q：想換系統但係經費有限，有無咩方法可以幫到餐廳完成數碼轉型？", desc: "A：政府推行科技券(TVP)支援本地企業/機構使用科技服務和方案，資助高達港幣60萬元，餐廳可以通過科技券助餐廳實現數碼轉型" },
      ]
    }
  },
  methods: {
    getR (index) {
      let len = this.lengItem;
      // l 310  
      // R 50
      let max = 100;
      let p = max / (len - 1);
      let t = 310 + (index * p);
      if (t > 360) t = Math.abs(t - 360)
      return t;
    }
  }
}
</script>

<style lang="less" scoped>
.aa {
  width: 1200px;
  margin: 0 auto;
  padding-top: 50px;
  overflow: hidden;
  .bb {
    height: 320px;
    position: relative;
    .bb-r {
      position: absolute;
      box-sizing: border-box;
      width: 1200px;
      height: 1200px;
      z-index: 1;
      top: 0;
      left: 0;
      border-radius: 50%;
      background: linear-gradient(
        180deg,
        var(--themeColor) 0%,
        rgba(190, 28, 66, 0) 30%,
        #fff 400%
      );
      opacity: 0.3;
    }
    .bb-i {
      position: absolute;
      width: 1200px;
      height: 1200px;
      z-index: 2;
      top: 0;
      left: 0;
      .bb-ic {
        width: 78px;
        height: 639px;
        position: absolute;
        left: calc(50% - 39px);
        top: -39px;
        transform-origin: bottom;
        div {
          width: 78px;
          height: 78px;
          text-align: center;
          line-height: 78px;
          background-color: aquamarine;
          border-radius: 50%;
        }
      }
    }
  }
  .cc {
    height: 500px;
    width: 1000px;
    background-color: #ccc;
    margin: 2px auto;
    position: relative;
    z-index: 5;
  }
}
.px-items {
  padding: 15px;
  .title-box {
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
    .title {
      width: calc(50% - 5px);
    }
  }
  .content-box {
    display: flex;
    justify-content: space-between;
    .desc {
      width: calc(50% - 5px);
      padding-bottom: 10px;
      border-bottom: 1px dashed #999;
    }
  }
}
</style>